// @flow
import type { ContactFormValues } from '../types';

import cleanseZohoData from '../../../functions/helpers/cleanseZohoData';

export default function handleSubmitMapValues(
  originalValues: ContactFormValues,
) {
  return {
    createLead: {
      Last_Name: originalValues.name,
      Email: originalValues.email,
      Phone: originalValues.phone || undefined,
      Description: originalValues.body,
      Industry: cleanseZohoData(originalValues.inputSector || undefined),
      $gclid: originalValues.gclid || undefined,
    },
  };
}
