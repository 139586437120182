// @flow
import * as React from 'react';

import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';

export type ClassKey = 'root' | 'name' | 'email';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  name: ?string,
  email?: ?string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

const styles = (theme: Theme) => ({
  root: {},
  name: {},
  email: {
    '* + &': {
      marginLeft: theme.spacing(2),
    },
  },
});

const AuthorInfo = ({
  name,
  email,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <div className={classnames(classes.root, className)} {...props}>
      <Typography variant="h6" className={classes.name}>
        {name}
      </Typography>
      {email ? (
        <Typography variant="h6" className={classes.email}>
          {email}
        </Typography>
      ) : null}
    </div>
  );
};

AuthorInfo.defaultProps = {
  className: undefined,
  email: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'AuthorInfo' })(AuthorInfo);
