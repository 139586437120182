// @flow

import * as React from 'react';

export default function getGoogleDriveVideoURL(videoURL?: ?string): ?string {
  const matchRegex =
    videoURL && videoURL.match('https://drive.google.com/file/d/(.*?)/');

  const videoID = matchRegex && matchRegex[1];

  return videoID
    ? `https://drive.google.com/uc?export=download&id=${videoID}`
    : null;
}
