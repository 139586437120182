// @flow
import { graphql, useStaticQuery } from 'gatsby';

import useFindCurrentLocaleNode from '~plugins/prismic/hooks/useFindCurrentLocaleNode';
import type { Query, PrismicNewsEntryArchive } from '~schema';

const query = graphql`
  query UseNewsEntryArchiveDefaultQuery {
    allPrismicNewsEntryArchive {
      nodes {
        id
        uid
        type
        lang
      }
    }
  }
`;

export default function useNewsEntryArchive(): ?PrismicNewsEntryArchive {
  return useFindCurrentLocaleNode<PrismicNewsEntryArchive>({
    nodes: useStaticQuery<Query>(query).allPrismicNewsEntryArchive?.nodes,
    toQuery: prismicNewsEntryArchive => ({ prismicNewsEntryArchive }),
    fromQuery: data => data?.prismicNewsEntryArchive,
  });
}
