// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  wrapper: {},
  title: {
    color: theme.palette.common.black,
  },
  subtitle: {
    '* ~ &': {
      marginTop: theme.spacing(3),
    },
    '& > *': {
      color: theme.palette.primary.main,
      fontFamily: theme.typography.h1.fontFamily,
      fontSize: theme.typography.h4.fontSize,
    },
  },
  partnerImage: {
    width: '100%',
    height: 'auto',
    maxWidth: 250,
    margin: 'auto',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
    },
    '& img': {
      objectFit: 'contain !important',
    },
  },
  useCaseLogo: {
    width: '100%',
    height: 'auto',
    maxWidth: 300,
    maxHeight: 150,
    margin: 'auto',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
      maxHeight: 90,
    },
    '& img': {
      objectFit: 'contain !important',
    },
  },
  additionalInfoWrapper: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      display: 'block',
    },
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
  },
  publicationDate: {
    '* + &': {
      marginTop: theme.spacing(2),
    },
  },
  tags: {},
  chip: {
    margin: theme.spacing(1),
  },
  authorInfo: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      '&::before': {
        margin: theme.spacing(0, 1, 0, 1),
        content: JSON.stringify('|'),
      },
    },
  },
  backgroundImageRoot: {},
  backgroundImageWrapper: {
    position: 'relative',
    display: 'flex',
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  caption: {
    textAlign: 'left',
    paddingTop: theme.spacing(1.5),
  },
});

export default styles;
