// @flow
import * as React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '~plugins/form/components/TextField';

import Form, {
  type Props as FormProps,
} from '~plugins/zoho-form/components/Form';
import useHandleOnSubmitMapValues from '~plugins/zoho-form/hooks/useHandleOnSubmitMapValues';
import withPartialSuccessReponseSnackbar from '~hocs/withPartialSuccessReponseSnackbar';
import FormLoadingButton from '~components/FormLoadingButton';
import StyledTextField, {
  type Variant as TextFieldVariant,
} from '~components/StyledTextField';
import CheckboxWithLabel from '~plugins/form/components/CheckboxWithLabel';
import RichText from '~plugins/prismic/components/RichText';
import hasRichTextFieldValue from '~plugins/prismic/helpers/hasRichTextFieldValue';
import useRecaptcha from '~hooks/useRecaptcha';
import fetchRecaptchaValidation from '~helpers/fetchRecaptchaValidation';

import type { ContactFormValues } from './types';
import messages from './messages';
import handleSubmitMapValues from './helpers/handleSubmitMapValues';
import type { PrismicContactForm } from '~schema';

const EnhancedForm = withPartialSuccessReponseSnackbar<
  ContactFormValues,
  FormProps<ContactFormValues>,
>()(Form);

export type ButtonColor = $ElementType<
  React.ElementConfig<typeof Button>,
  'color',
>;

const lightColor = '#FFF';
const darkColor = '#f4f5f8';

export type ClassKey = 'root' | 'checkbox' | 'action' | 'disabledButton';

const styles = (theme: Theme) => ({
  root: {},
  checkbox: {
    textAlign: 'left',
    '& .MuiCheckbox-root': {
      color: ({ textFieldColor }) =>
        textFieldColor === 'light' ? theme.palette.common.white : undefined,
    },
    '& a': {
      color: ({ textFieldColor }) =>
        textFieldColor === 'light'
          ? theme.palette.common.white
          : theme.palette.primary.main,
    },
  },
  action: {
    marginTop: theme.spacing(2),
  },
  disabledButton: ({ buttonColor }) => ({
    ...(buttonColor === 'primary'
      ? {
          backgroundColor: `${theme.palette.primary.dark} !important`,
        }
      : {
          backgroundColor: `${theme.palette.grey[400]} !important`,
        }),
  }),
});

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<$Diff<FormProps<ContactFormValues>, { onSubmitMapValues: any }>>,
  node: ?PrismicContactForm,
  inputSector: ?string,
  className?: string,
  textFieldVariant?: TextFieldVariant,
  textFieldColor?: 'light' | 'dark',
  buttonColor?: ButtonColor,
  onSubmitMapValues?: $ElementType<
    FormProps<ContactFormValues>,
    'onSubmitMapValues',
  >,
};

const ContactForm = ({
  textFieldVariant,
  textFieldColor,
  buttonColor,
  initialValues,
  inputSector,
  onSubmitMapValues,
  node,
  className,
  classes,
  ...props
}: Props) => {
  const intl = useIntl();
  const [recaptcha, token] = useRecaptcha();

  const handleSubmit = React.useCallback(
    () => fetchRecaptchaValidation(token),
    [token],
  );

  return (
    <EnhancedForm
      {...props}
      node={node}
      messages={messages}
      onSubmit={handleSubmit}
      onSubmitMapValues={useHandleOnSubmitMapValues({
        onSubmitMapValues,
        handleSubmitMapValues,
      })}
      initialValues={React.useMemo(
        () => ({
          name: '',
          email: '',
          phone: '',
          body: '',
          inputSector: inputSector || null,
          ...initialValues,
        }),
        [initialValues],
      )}
      className={classes.root}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            type="text"
            name="name"
            variant={textFieldVariant}
            TextField={TextField}
            label={
              node?.data?.label_name?.text || (
                <FormattedMessage {...messages.labels.name} />
              )
            }
            inputProps={{ 'aria-label': 'Name input field' }}
            color={textFieldColor === 'light' ? lightColor : darkColor}
            fullWidth
          />
        </Grid>
        {node?.data?.show_phone ? (
          <Grid item xs={12} sm={6}>
            <StyledTextField
              type="text"
              name="phone"
              variant={textFieldVariant}
              TextField={TextField}
              label={
                node?.data?.label_phone?.text || (
                  <FormattedMessage {...messages.labels.phone} />
                )
              }
              inputProps={{ 'aria-label': 'Phone input field' }}
              color={textFieldColor === 'light' ? lightColor : darkColor}
              fullWidth
            />
          </Grid>
        ) : null}

        <Grid item xs={12} sm={node?.data?.show_phone ? 12 : 6}>
          <StyledTextField
            type="email"
            name="email"
            variant={textFieldVariant}
            TextField={TextField}
            label={
              node?.data?.label_email?.text || (
                <FormattedMessage {...messages.labels.email} />
              )
            }
            inputProps={{ 'aria-label': 'Email input field' }}
            color={textFieldColor === 'light' ? lightColor : darkColor}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <StyledTextField
            type="text"
            name="body"
            variant={textFieldVariant}
            TextField={TextField}
            multiline
            rows={4}
            label={
              node?.data?.label_body?.text || (
                <FormattedMessage {...messages.labels.body} />
              )
            }
            inputProps={{ 'aria-label': 'Body input field' }}
            color={textFieldColor === 'light' ? lightColor : darkColor}
            fullWidth
          />
        </Grid>

        {hasRichTextFieldValue(node?.data?.label_accept_privacy_policy) ? (
          <Grid item xs={12}>
            <CheckboxWithLabel
              name="acceptPrivacyPolicy"
              type="checkbox"
              indeterminate={false}
              required
              label={<RichText {...node?.data?.label_accept_privacy_policy} />}
              aria-label={
                node?.data?.label_accept_privacy_policy?.text ||
                intl.formatMessage(messages.labels.privacyPolicy)
              }
              className={classes.checkbox}
              color={textFieldColor === 'light' ? 'secondary' : 'primary'}
              fullWidth
            />
          </Grid>
        ) : null}
      </Grid>

      {recaptcha}

      <FormLoadingButton
        type="submit"
        variant="contained"
        color={buttonColor}
        spinnerColor={textFieldColor === 'light' ? 'primary' : 'secondary'}
        aria-label="submit"
        fullWidth
        className={classes.action}
        classes={{ disabled: classes.disabledButton }}
      >
        {node?.data?.label_submit?.text || (
          <FormattedMessage {...messages.labels.submit} />
        )}
      </FormLoadingButton>
    </EnhancedForm>
  );
};

ContactForm.defaultProps = {
  className: undefined,
  textFieldVariant: undefined,
  textFieldColor: undefined,
  buttonColor: undefined,
  onSubmitMapValues: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'ContactForm' })(ContactForm);
