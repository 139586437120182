// @flow
import * as React from 'react';
import { Field, type FieldProps } from 'formik';
import FormControl, {
  type FormControlProps as FormControlPropsT,
} from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CheckboxWithLabel as FormikCheckboxWithLabel } from 'formik-material-ui';
import get from 'lodash/get';
import omit from 'lodash/fp/omit';
import pick from 'lodash/fp/pick';

import withFieldMapProps from '../hocs/withFieldMapProps';
import type { FormComponentProps } from '../types';

type FormikCheckboxWithLabelProps = React.ElementConfig<
  typeof FormikCheckboxWithLabel,
>;

type LabelProps = $ElementType<FormikCheckboxWithLabelProps, 'Label'>;

type OwnProps = { FormControlProps?: FormControlPropsT };

export type Props = FormComponentProps<
  typeof FormikCheckboxWithLabel,
  {
    ...$Exact<FormControlPropsT>,
    ...$Exact<OwnProps>,
    ...$Exact<$Diff<LabelProps, { control: any }>>,
  },
  { Label: any },
>;

type CheckboxWithLabelInnerProps = FormikCheckboxWithLabelProps &
  FieldProps &
  OwnProps;

const checkboxWithLabelPropsList = [
  // SwitchBase props
  'checkedIcon',
  'icon',
  'autoFocus',
  'checked',
  'defaultChecked',
  'disabled',
  'disableRipple',
  'inputProps',
  'inputRef',
  'name',
  'onChange',
  'readOnly',
  'required',
  'tabIndex',
  'value',
  // Checkbox props
  'color',
  'icon',
  'checkedIcon',
  'indeterminate',
  'indeterminateIcon',
  // CheckboxWithLabel props
  'Label',
  'type',
];

const omitCheckboxWithLabelProps = omit(checkboxWithLabelPropsList);
const pickCheckboxWithLabelProps = pick(checkboxWithLabelPropsList);

const CheckboxWithLabelInner = ({
  field,
  form,
  ...props
}: CheckboxWithLabelInnerProps) => {
  const error: ?string = get(form.errors, field.name);
  const touched: ?boolean = get(form.touched, field.name);
  const showError = !!(touched && error);
  return (
    <FormControl {...omitCheckboxWithLabelProps(props)} error={showError}>
      <FormikCheckboxWithLabel
        {...pickCheckboxWithLabelProps(props)}
        field={field}
        form={form}
      />
      {showError ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};

const EnhancedCheckboxWithLabelInner = withFieldMapProps<CheckboxWithLabelInnerProps>()(
  CheckboxWithLabelInner,
);

const CheckboxWithLabel = ({
  label,
  disabled,
  labelPlacement,
  ...props
}: Props) => (
  <Field
    component={EnhancedCheckboxWithLabelInner}
    {...props}
    Label={React.useMemo(
      () => ({
        label,
        disabled,
        labelPlacement,
      }),
      [label, disabled, labelPlacement],
    )}
  />
);

export default CheckboxWithLabel;
