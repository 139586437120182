// @flow

export type Config = {|
  formName?: string,
  contentSource?: string,
  inputSector: string,
  appendInputSectorToContentSource?: boolean,
|};

export type ContactFormTrackingProps = {|
  formName: string,
  contentSource: string,
  inputSector: string,
|};

export const FORM_NAME = 'Contact';

export const NAMESPACE = process.env.GATSBY_FRONTEND_ENV_VAR_NAMESPACE;

if (!NAMESPACE) {
  throw new Error('Missing process.env.GATSBY_FRONTEND_ENV_VAR_NAMESPACE');
}

export const CONTENT_SOURCE = 'Formulario WEB';

const getContactFormTrackingProps = ({
  formName = FORM_NAME,
  contentSource = CONTENT_SOURCE,
  inputSector,
  appendInputSectorToContentSource,
}: Config): ContactFormTrackingProps => {
  const contentSourceNamespaced = `${NAMESPACE} - ${contentSource}`;
  return {
    formName,
    contentSource: appendInputSectorToContentSource
      ? `${contentSourceNamespaced} ${inputSector}`
      : contentSourceNamespaced,
    inputSector: inputSector,
  };
};

export default getContactFormTrackingProps;
