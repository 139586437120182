// @flow
import { graphql, useStaticQuery } from 'gatsby';

import useFindCurrentLocaleNode from '~plugins/prismic/hooks/useFindCurrentLocaleNode';
import type { Query, PrismicContactForm } from '~schema';

const query = graphql`
  query UseContactFormDefaultQuery {
    allPrismicContactForm {
      nodes {
        lang
        id
        data {
          name {
            text
            html
          }
          title {
            text
            html
          }
          description {
            text
            html
          }
          background_image {
            alt
            fluid(maxWidth: 1000) {
              ...GatsbyPrismicImageFluid
            }
          }
          label_name {
            text
            html
          }
          label_email {
            text
            html
          }
          label_phone {
            text
            html
          }
          show_phone
          label_body {
            text
            html
          }
          label_accept_privacy_policy {
            text
            html
          }
          label_submit {
            text
            html
          }
          error_email {
            text
            html
          }
          error_must_accept_privacy_policy {
            text
            html
          }
          error_required {
            text
            html
          }
          event_error {
            text
            html
          }
          event_reject {
            text
            html
          }
          event_success {
            text
            html
          }
        }
      }
    }
  }
`;

export default function useContactForm(): ?PrismicContactForm {
  return useFindCurrentLocaleNode<PrismicContactForm>({
    nodes: useStaticQuery<Query>(query).allPrismicContactForm?.nodes,
    toQuery: prismicContactForm => ({ prismicContactForm }),
    fromQuery: data => data?.prismicContactForm,
  });
}
