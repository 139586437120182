// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import classnames from 'classnames';
import { Parallax } from 'react-scroll-parallax';
import Fade from 'react-reveal/Fade';
import uniq from 'lodash/uniq';

import NodeLink from '~plugins/prismic/components/NodeLink';
import ResPadding from '~plugins/material-ui/components/ResPadding';
import RichText, {
  hasRichTextFieldValue,
} from '~plugins/prismic/components/RichText';
import NodeImage, {
  hasNodeImageValue,
} from '~plugins/prismic/components/NodeImage';
import useFiltersState from '~components/FiltersProvider/hooks/useFiltersState';

import getLinkedAuthorType from '../../helpers/getLinkedAuthorType';
import AuthorInfo from '~components/AuthorInfo';
import useNewsEntryArchive from '~hooks/useNewsEntryArchive';
import getLinkedEntryTag from './helpers/getLinkedEntryTag';

import type { Props } from './types';
import styles from './styles';

const ComplexHeader = ({
  title,
  subtitle,
  authorLink,
  partnerTitleImage,
  backgroundImage,
  leftParallaxBackground,
  rightParallaxBackground,
  activateLeftParallaxEffect,
  activateRightParallaxEffect,
  useCaseLogo,
  publicationDate,
  tags,
  containerMaxWidth,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const authorInfo = getLinkedAuthorType(authorLink)?.data;

  const prismicNewsEntryArchive = useNewsEntryArchive();

  const selectedTags = useFiltersState().entryTags;

  return (
    <div className={classnames(classes.root, className)} {...props}>
      <Container
        component={ResPadding}
        vertical
        maxWidth={containerMaxWidth || 'sm'}
        className={classes.wrapper}
      >
        <NodeImage data={useCaseLogo} className={classes.useCaseLogo} />
        <Fade bottom cascade distance="40%">
          <div>
            <RichText {...title} className={classes.title} />
            {hasRichTextFieldValue(subtitle) && (
              <RichText {...subtitle} className={classes.subtitle} />
            )}
            <NodeImage
              data={partnerTitleImage}
              className={classes.partnerImage}
            />
          </div>
        </Fade>
        {publicationDate ? (
          <div className={classes.additionalInfoWrapper}>
            <Typography variant="h6" className={classes.publicationDate}>
              {publicationDate}
            </Typography>
            {authorInfo ? (
              <AuthorInfo
                name={authorInfo?.name?.text}
                className={classes.authorInfo}
              />
            ) : null}
          </div>
        ) : null}

        {tags && tags.length > 0 ? (
          <div className={classes.tags}>
            {tags.map(link => {
              const tag = getLinkedEntryTag(link.tag);
              return tag &&
                tag.uid &&
                tag.data &&
                tag.data.name &&
                tag.data.name.text ? (
                <Chip
                  key={tag.uid || tag.id}
                  label={tag.data.name.text}
                  color="primary"
                  clickable
                  component={NodeLink}
                  node={prismicNewsEntryArchive}
                  params={{
                    entryTags: uniq([...selectedTags, tag?.uid]),
                  }}
                  className={classes.chip}
                />
              ) : null;
            })}
          </div>
        ) : null}
      </Container>
      {hasNodeImageValue(backgroundImage) ? (
        <Container
          component={ResPadding}
          vertical
          className={classes.backgroundImageRoot}
        >
          <div className={classes.backgroundImageWrapper}>
            <NodeImage
              data={backgroundImage}
              className={classes.backgroundImage}
            />
            <Parallax
              styleOuter={{
                width: '25%',
                height: 'auto',
                position: 'absolute',
                left: '0px',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              {...(activateLeftParallaxEffect ? { y: [10, -40] } : null)}
            >
              <NodeImage
                data={leftParallaxBackground}
                className={classes.backgroundImage}
              />
            </Parallax>
            <Parallax
              styleOuter={{
                width: '25%',
                height: 'auto',
                position: 'absolute',
                right: '0px',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              {...(activateRightParallaxEffect ? { y: [-10, 40] } : null)}
            >
              <NodeImage
                data={rightParallaxBackground}
                className={classes.backgroundImage}
              />
            </Parallax>
          </div>
          {backgroundImage && backgroundImage?.alt && (
            <Container>
              <Typography
                variant="caption"
                component="p"
                className={classes.caption}
              >
                {backgroundImage.alt}
              </Typography>
            </Container>
          )}
        </Container>
      ) : (
        undefined
      )}
    </div>
  );
};

ComplexHeader.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'ComplexHeader' })(ComplexHeader);
