// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import classnames from 'classnames';
import ReactPlayer from 'react-player';
import type { Props } from './types';
import styles from './styles';

import getProviderVideoURL from './helpers/getProviderVideoURL';

const VideoPlayer = ({
  url,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const videoURL = getProviderVideoURL(url);

  return (
    <ReactPlayer
      className={classnames(classes.root, className)}
      url={videoURL}
      {...props}
    />
  );
};

VideoPlayer.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'VideoPlayer' })(VideoPlayer);
